import { WhatsApp } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { WFBProps } from "./WhatsappFabButtonTypes";

function WhatsappFabButton({ phone, bottomPosition = "70px" }: WFBProps) {
  if (!phone) return null;

  const cleanedPhone = phone.replace(/\D/g, "");
  const wppUrl = `https://wa.me/55${cleanedPhone}`;

  return (
    <Fab
      color="success"
      sx={{ bottom: bottomPosition, right: "2vh" }}
      href={wppUrl}
      target="_blank"
    >
      <WhatsApp />
    </Fab>
  );
}

export { WhatsappFabButton };
