import { styled } from "styled-components";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div<{ show?: boolean }>`
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1200;
  background-color: ${theme.primary};
  color: ${theme.background};
  padding: 10px 0;
  display: ${({ show }) => (show ? "block" : "none")};
`;
