import { EastRounded, ShoppingCart } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { numberToBRL, textPlural } from "../../utils/formatters";
import { Wrapper } from "./CartResumeStyles";
import useCart from "../../hooks/useCart";

function CartResume() {
  const navigate = useNavigate();
  const { data: cart } = useCart();

  const handleOpenCheckout = () => {
    navigate(`./carrinho`);
  };

  return (
    <Wrapper onClick={handleOpenCheckout} show={cart.products.length > 0}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ShoppingCart fontSize="large" />
          </Grid>
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <div>
              <b>{numberToBRL(cart.total)}</b>
            </div>
            <div>
              {cart.products.length}{" "}
              {textPlural("produto", "produtos", cart.products.length)}
            </div>
          </Grid>
          <Grid item>
            <EastRounded fontSize="medium" />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default CartResume;
