import {
  AddRounded,
  RemoveRounded,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useDevice from "../../hooks/useDevice";
import useDialog from "../../hooks/useDialog";
import { ComplementInterfaceAPI } from "../../interfaces/complement";
import { homeDataState } from "../../state/atoms/homeData";
import {
  homeDataAllProducts,
  homeDataIsOpenNow,
} from "../../state/selectors/homeData";
import { theme } from "../../styles/theme";
import { numberToBRL, textPlural } from "../../utils/formatters";
import {
  CloseButton,
  DialogContentStyled,
  Image,
  Price,
  Description,
  Title,
} from "./ProductPreviewStyles";
import useCart from "../../hooks/useCart";
import { ProductInterfaceAPI } from "../../interfaces/product";
import useCompanyFeatures from "../../hooks/companyPlan";
import { getShopContext } from "../../utils/setup";

const DESCRIPTION_MAX_LENGTH = 250;

function ProductPreview() {
  const dialog = useDialog();
  const navigate = useNavigate();
  const { id } = useParams();
  const cart = useCart();
  const homeData = useRecoilValue(homeDataState);
  const allProducts = useRecoilValue(homeDataAllProducts);
  const isOpenNow = useRecoilValue(homeDataIsOpenNow);
  const product = allProducts.find((p) => p.id === id);
  const [selectedProductsKit, setSelectedProductsKit] = useState<string[]>([]);
  const [selectedComplements, setSelectedComplements] = useState<string[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [openModalProductAdded, setOpenModalProductAdded] = useState(false);
  const { isMobile } = useDevice();
  const { hasFeature } = useCompanyFeatures();

  if (!product) {
    return <></>;
  }

  const allowedProductsKit = allProducts.filter((p) =>
    product.kit_products_allowed?.includes(p.id)
  );

  const checkOrdersPermission = () => {
    const shopContext = getShopContext();

    if (shopContext === "root") return hasFeature("root-orders");
    if (shopContext === "delivery") return hasFeature("delivery-orders");
    if (shopContext === "cardapio") return hasFeature("cardapio-orders");

    return false;
  };

  const onClose = () => navigate(`../`);

  const addToCart = () => {
    if (!isOpenNow && !hasFeature("scheduled-orders")) {
      dialog(
        "Loja fechada 😢",
        "A loja está fechada no momento. Verifique o horário de funcionamento na página inicial."
      );
      return;
    }

    if (
      product.kit &&
      !product.kit_fixed &&
      selectedProductsKit.length < product.kit_limit
    ) {
      dialog(
        "Complete o pedido 🛒",
        `Escolha ${product.kit_limit} ${textPlural(
          "item",
          "itens",
          product.kit_limit
        )} antes de adicionar o produto no carrinho.`
      );
      return;
    }

    cart.addProduct(
      {
        product_id: product.id,
        kit_products: product.kit_fixed
          ? product.kit_products_allowed
          : selectedProductsKit,
        product_complements_id: selectedComplements,
      },
      () => {
        setOpenModalProductAdded(true);
      }
    );
  };

  const onChangeKitItemQuantity = (id: string, value: number) => {
    if (value > 0) {
      setSelectedProductsKit((prev) => [...prev, id]);
    } else {
      setSelectedProductsKit((prev) => {
        const temp = [...prev];
        const firstIndex = prev.findIndex((p) => p === id);
        temp.splice(firstIndex, 1);
        return temp;
      });
    }
  };

  const onChangeComplementQuantity = (id: string, value: number) => {
    if (value > 0) {
      setSelectedComplements((prev) => [...prev, id]);
    } else {
      setSelectedComplements((prev) => {
        const temp = [...prev];
        const firstIndex = prev.findIndex((p) => p === id);
        temp.splice(firstIndex, 1);
        return temp;
      });
    }
  };

  const onChangeUniqueComplement = (
    id: string,
    items: ComplementInterfaceAPI[]
  ) => {
    setSelectedComplements((prev) => {
      const all = items.map((p) => p.id);
      const temp = prev.filter((p) => !all.includes(p));
      temp.push(id);
      return temp;
    });
  };

  const getKitItemCountAdded = (id: string) => {
    return selectedProductsKit.filter((p) => p === id)?.length || 0;
  };

  const getComplementCountAdded = (id: string) => {
    return selectedComplements.filter((p) => p === id)?.length || 0;
  };

  const getUniqueComplementAdded = (id: string) => {
    return Boolean(selectedComplements.find((p) => p === id));
  };

  const isMaxProductAdded = (p: ProductInterfaceAPI) => {
    const qtdAddedCart = cart.productsQuantity[p.id] ?? 0;
    const qtdAddedKit = selectedProductsKit.filter((pk) => pk === p.id).length;
    const qtdAddedTotal = qtdAddedCart + qtdAddedKit;

    if (qtdAddedTotal >= p.stock_amount) {
      return true;
    }

    if (selectedProductsKit.length === product.kit_limit) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Dialog
        open={!!product.id}
        onClose={onClose}
        scroll="paper"
        fullScreen={isMobile}
        fullWidth={!isMobile}
        maxWidth="sm"
      >
        <DialogContentStyled>
          <CloseButton className="fa-solid fa-xmark" onClick={onClose} />
          <Image src={product.photo_path} />
          <DialogContent tabIndex={-1}>
            <Title>{product.name}</Title>
            <Price>{numberToBRL(product.price)}</Price>

            {checkOrdersPermission() &&
            product.full_description?.length > DESCRIPTION_MAX_LENGTH ? (
              <Description>
                {showFullDescription
                  ? product.full_description
                  : product.full_description.slice(0, DESCRIPTION_MAX_LENGTH)}
                {showFullDescription ? "" : "..."}
                {` `}
                <Typography
                  onClick={() => setShowFullDescription((prev) => !prev)}
                  color={theme.primary}
                  fontSize="inherit"
                  display="inline-block"
                  fontWeight="bold"
                >
                  {showFullDescription ? "ver menos" : "ver mais"}
                </Typography>
              </Description>
            ) : (
              <Description>
                {product.full_description ?? product.short_description}
              </Description>
            )}

            {product.kit && product.kit_fixed && (
              <List style={{ marginBottom: "20px" }}>
                {allowedProductsKit.map((p) => {
                  const count = product.kit_products_allowed.filter(
                    (pk) => pk === p.id
                  )?.length;

                  return (
                    <>
                      <Divider />
                      <ListItem
                        disableGutters
                        style={{ gap: "20px", padding: "20px 0 20px 0" }}
                        key={p.id}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt={p.name}
                            src={p.photo_path ?? ""}
                            variant="rounded"
                            sx={{ width: 90, height: 90 }}
                          />
                        </ListItemAvatar>
                        <Box
                          flex={1}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          gap="5px"
                        >
                          <Typography flex={2} fontWeight="bold">
                            <Typography
                              fontWeight="bold"
                              color={theme.primary}
                              display="inline"
                            >
                              {count} un.
                            </Typography>{" "}
                            {p.name}
                          </Typography>
                          <Typography variant="body2">
                            {p.short_description}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  );
                })}
              </List>
            )}

            {product.kit && !product.kit_fixed && (
              <List
                style={{ marginBottom: "20px" }}
                subheader={
                  <ListSubheader disableSticky disableGutters>
                    <Typography
                      color={theme.primary}
                      fontWeight="bold"
                      marginY="15px"
                      fontSize="16px"
                    >
                      Escolha {product.kit_limit}{" "}
                      {textPlural("item", "itens", product.kit_limit)} para
                      incluir:
                    </Typography>
                  </ListSubheader>
                }
              >
                {allowedProductsKit.map((p) => (
                  <>
                    <Divider />
                    <ListItem
                      disableGutters
                      style={{ gap: "20px", padding: "20px 0 20px 0" }}
                      alignItems="flex-start"
                      key={p.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={p.name}
                          src={p.photo_path ?? ""}
                          variant="rounded"
                          sx={{ width: 90, height: 90 }}
                        />
                      </ListItemAvatar>
                      <Box
                        flex={1}
                        display="flex"
                        flexDirection="column"
                        gap="10px"
                      >
                        <Typography flex={2} fontWeight="bold">
                          {p.name}
                        </Typography>
                        <Typography variant="body2">
                          {p.short_description}
                        </Typography>
                        {hasFeature("stock-products") && (
                          <Typography variant="body2" color={theme.tertiary}>
                            {p.stock_amount}{" "}
                            {textPlural(
                              "unidade disponível",
                              "unidades disponíveis",
                              p.stock_amount
                            )}
                          </Typography>
                        )}
                        <TextField
                          size="small"
                          value={getKitItemCountAdded(p.id)}
                          style={{
                            background: "var(--background)",
                          }}
                          inputProps={{
                            style: {
                              textAlign: "center",
                              fontFamily: "inherit",
                              fontSize: "16px",
                            },
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: 0,
                              paddingRight: 0,
                            },
                            startAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="start"
                                  onClick={() =>
                                    onChangeKitItemQuantity(p.id, -1)
                                  }
                                  disabled={!getKitItemCountAdded(p.id)}
                                  size="small"
                                >
                                  <RemoveRounded />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    onChangeKitItemQuantity(p.id, 1)
                                  }
                                  disabled={isMaxProductAdded(p)}
                                  size="small"
                                >
                                  <AddRounded />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </ListItem>
                  </>
                ))}
              </List>
            )}

            {product.product_complements_id.map((gComplement, i) => (
              <Fragment key={gComplement.id}>
                <Divider />
                <List
                  style={{ marginBottom: "20px" }}
                  subheader={
                    <ListSubheader disableSticky disableGutters>
                      <Typography
                        color={theme.primary}
                        fontWeight="bold"
                        marginY="15px"
                        fontSize="16px"
                      >
                        {gComplement.alias}
                      </Typography>
                    </ListSubheader>
                  }
                >
                  {gComplement.items.map((p) => (
                    <ListItem disableGutters style={{ gap: "10px" }} key={p.id}>
                      <div style={{ flex: 2 }}>
                        <Typography>{p.title}</Typography>
                        <Typography color={theme.secondary}>
                          {p.price > 0 && <span>+ {numberToBRL(p.price)}</span>}
                        </Typography>
                      </div>
                      {gComplement.type === "UNIQUE" && (
                        <Checkbox
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          checked={getUniqueComplementAdded(p.id)}
                          onChange={() =>
                            onChangeUniqueComplement(p.id, gComplement.items)
                          }
                        />
                      )}
                      {gComplement.type === "MULTIPLE" && (
                        <TextField
                          size="small"
                          value={getComplementCountAdded(p.id)}
                          style={{
                            flex: 1,
                            background: "var(--background)",
                          }}
                          inputProps={{
                            style: {
                              textAlign: "center",
                              fontFamily: "inherit",
                              fontSize: "16px",
                            },
                          }}
                          InputProps={{
                            style: {
                              paddingLeft: 0,
                              paddingRight: 0,
                            },
                            startAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="start"
                                  onClick={() =>
                                    onChangeComplementQuantity(p.id, -1)
                                  }
                                  disabled={!getComplementCountAdded(p.id)}
                                  size="small"
                                >
                                  <RemoveRounded />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    onChangeComplementQuantity(p.id, 1)
                                  }
                                  size="small"
                                >
                                  <AddRounded />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </Fragment>
            ))}
          </DialogContent>

          {product.kit && selectedProductsKit.length > 0 && (
            <Box height="100px" />
          )}

          {checkOrdersPermission() && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "var(--border)",
                padding: "15px 25px",
                boxSizing: "border-box",
                gap: "10px",
                width: "100%",
                ...(product.kit &&
                  selectedProductsKit.length > 0 && {
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    boxShadow: "0px 0px 25px rgba(0,0,0,0.15)",
                  }),
              }}
            >
              {product.kit && !product.kit_fixed && (
                <Typography textAlign="center" variant="body2">
                  <Typography
                    color={theme.primary}
                    display="inline"
                    variant="body2"
                    fontWeight="bold"
                  >
                    {selectedProductsKit.length} de {product.kit_limit} itens
                  </Typography>{" "}
                  adicionados no kit
                </Typography>
              )}

              {product.stock_amount <= 0 ||
              (cart.productsQuantity[product.id] ?? 0) >=
                product.stock_amount ? (
                <Button
                  onClick={addToCart}
                  startIcon={<ShoppingCartOutlined />}
                  size="large"
                  variant="contained"
                  disableElevation
                  style={{ flex: 1 }}
                  disabled
                >
                  Estoque indisponível
                </Button>
              ) : (
                <Button
                  onClick={addToCart}
                  startIcon={
                    cart.loading ? undefined : <ShoppingCartOutlined />
                  }
                  size="large"
                  variant="contained"
                  disableElevation
                  style={{ flex: 1 }}
                >
                  {cart.loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Adicionar"
                  )}
                </Button>
              )}
            </div>
          )}
        </DialogContentStyled>
      </Dialog>

      <Dialog
        open={openModalProductAdded}
        keepMounted
        onClose={() => setOpenModalProductAdded(false)}
        style={{ zIndex: 2000 }}
      >
        <DialogTitle>Produto adicionado 🛒</DialogTitle>

        <DialogContent>
          <DialogContentText>
            O produto {product.name} foi incluído no seu pedido. O que deseja
            fazer agora?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Continuar comprando</Button>
          <Button onClick={() => navigate(`../carrinho`)}>
            Ver meu carrinho
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ProductPreview;
