import { useMediaQuery, useTheme } from "@mui/material";

const useDevice = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return {
    isDesktop,
    isMobile: !isDesktop,
  };
};

export default useDevice;
