import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { dialogProviderState } from "../state/atoms/dialog";

const useDialog = () => {
  const [_, setProviderState] = useRecoilState(dialogProviderState);

  const showDialog = (title: string, description?: string) =>
    setProviderState({
      open: true,
      title,
      description,
    });

  return showDialog;
};

export default useDialog;
