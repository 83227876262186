import { Container, Grid } from "@mui/material";
import { css, styled } from "styled-components";
import { theme } from "../../styles/theme";
import { TitleProps } from "./SectionHeaderTypes";

export const GridStyled = styled(Grid)<{ bottomDivider?: boolean }>`
  ${({ bottomDivider }) =>
    bottomDivider &&
    css`
      border-bottom: 2px solid ${theme.border};
      padding-bottom: 5px;
      margin-bottom: 5px;
    `};
`;

export const Title = styled.h2<TitleProps>`
  color: ${({ variant = "text" }) => theme[variant]};
  font-size: 18px;
`;

export const Description = styled.p`
  color: ${theme.tertiary};
`;
