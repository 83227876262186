import { CheckoutSectionInterface } from "./CheckoutTypes";

export default function CheckoutSection({
  show,
  children,
}: CheckoutSectionInterface) {
  return (
    <div style={{ display: show ? "block" : "none", paddingBottom: "130px" }}>
      {children}
    </div>
  );
}
