import { selector } from "recoil";
import { cartState } from "../atoms/cart";

export const cartProductsQuantity = selector({
  key: "cartProductsQuantity",
  get: ({ get }) => {
    const cart = get(cartState);

    let products: { [k: string]: number } = {};

    cart.products.forEach((p) => {
      if (p.kit) {
        p.kit_products.forEach((kp) => {
          products[kp.product_id] = (products[kp.product_id] ?? 0) + 1;
        });
      } else {
        products[p.product_id] = (products[p.product_id] ?? 0) + 1;
      }
    });

    return products;
  },
});
