import dayjs from "dayjs";
import { GenerateTimeSlotsInterface } from "../interfaces/utils";
import { WeekdayNameType } from "../interfaces/home";

export const generateTimeSlots = ({
  start,
  end,
  interval = 15,
}: GenerateTimeSlotsInterface): string[] => {
  if (!start || !end) return [];

  const slots: string[] = [];
  const currDay = dayjs().format("YYYY-MM-DD");
  const startTime = dayjs(`${currDay}T${start}`);
  const endTime = dayjs(`${currDay}T${end}`);

  let currentTime = startTime;

  while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
    slots.push(currentTime.format("HH:mm"));
    currentTime = currentTime.add(interval, "minute");
  }

  return slots;
};

export const getWeekdayName = (weekdayNumber: number): WeekdayNameType => {
  const names: WeekdayNameType[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return names[weekdayNumber];
};
