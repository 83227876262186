import { selector } from "recoil";
import { weekdayNumToNamePt } from "../../utils/parsers";
import { homeDataState } from "../atoms/homeData";

export const homeDataAllProducts = selector({
  key: "homeDataAllProducts",
  get: ({ get }) => {
    const homeData = get(homeDataState);
    return homeData.categories.flatMap((category) => category.products);
  },
});

export const homeDataIsOpenNow = selector({
  key: "homeDataIsOpenNow",
  get: ({ get }) => {
    const homeData = get(homeDataState);

    const currDate = new Date();
    const currTime = currDate.toTimeString().slice(0, 5); // 00:00

    const weekdayName = weekdayNumToNamePt(currDate.getDay());

    const currOpeningHour = homeData.company.opening_hours[weekdayName];
    const isOpenNow =
      currTime >= currOpeningHour.initial! &&
      currTime <= currOpeningHour.final!;
    return isOpenNow;
  },
});
