import { Container, TextField } from "@mui/material";
import { styled } from "styled-components";
import { theme } from "../styles/theme";

const profileSizeDesktop = "180px";
const profileSizeMobile = "120px";

export const PageWrapper = styled.section`
  background-color: ${theme.background};
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 50px;
`;

export const ContainerStyled = styled(Container)`
  @media (max-width: 768px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

export const Cover = styled.div<{ background: string }>`
  background: url(${({ background }) => background}) ${theme.border};
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: auto;
  padding-top: 30%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  margin-bottom: calc(${profileSizeDesktop} / 2);

  @media (max-width: 768px) {
    padding-top: 40%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-bottom: calc(${profileSizeMobile} / 2);
  }
`;

export const Profile = styled.div<{ background: string }>`
  background: url(${({ background }) => background}) ${theme.border};
  background-size: cover;
  background-position: center center;
  border-radius: 100%;
  border: 6px solid ${theme.background};
  position: absolute;
  left: calc(50% - (${profileSizeDesktop} / 2));
  bottom: calc(-1 * (${profileSizeDesktop} / 2));
  width: ${profileSizeDesktop};
  height: ${profileSizeDesktop};

  @media (max-width: 768px) {
    left: calc(50% - (${profileSizeMobile} / 2));
    bottom: calc(-1 * (${profileSizeMobile} / 2));
    width: ${profileSizeMobile};
    height: ${profileSizeMobile};
  }
`;

export const Header = styled.header`
  text-align: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  h1 {
    font-size: 22px;
    color: ${theme.text};
    max-width: 700px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: ${theme.text};
    max-width: 700px;
  }
`;

export const TabsWrapper = styled.nav`
  background-color: ${theme.background};
  position: sticky;
  top: 0px;
  width: 100%;
  margin: 25px 0 25px 0;
  z-index: 1100;
`;

export const OpeningHoursWrapper = styled.div`
  text-align: center;
`;
