import { atom } from "recoil";
import { DialogProviderInterface } from "../../components/DialogProvider/DialogProviderTypes";

export const dialogProviderState = atom<DialogProviderInterface>({
  key: "dialogProvider",
  default: {
    open: false,
    title: "",
    description: "",
  },
});
