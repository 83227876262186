import { atom } from "recoil";
import { HomeDataInterface } from "../../interfaces/home";

export const homeDataState = atom<HomeDataInterface>({
  key: "homeData",
  default: {
    company: {
      id: "",
      name: "",
      color: {
        primary: "",
        secondary: "",
        tertiary: "",
        border: "",
        background: "",
        document: "",
        text: "",
        success: "",
        error: "",
        warning: "",
      },
      cover_photo_url: "",
      description: "",
      profile_photo_url: "",
      plan: "basic",
      address: "",
      whatsapp: null,
      features: [],
      opening_hours: {
        monday: { initial: null, final: null },
        tuesday: { initial: null, final: null },
        wednesday: { initial: null, final: null },
        thursday: { initial: null, final: null },
        friday: { initial: null, final: null },
        saturday: { initial: null, final: null },
        sunday: { initial: null, final: null },
      },
    },
    categories: [],
  },
});
