import { CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useRecoilState } from "recoil";
import { DialogProvider } from "./components/DialogProvider/DialogProvider";
import { HomeDataInterface } from "./interfaces/home";
import { routes } from "./routes";
import { api } from "./services/api";
import { homeDataState } from "./state/atoms/homeData";
import * as Sentry from "@sentry/browser";
import {
  createThemeMUI,
  createThemeVariablesCSS,
  themeDefault,
} from "./styles/theme";

export default function App() {
  const router = createBrowserRouter(routes);
  const slug = window.slug;

  const [themeMui, setThemeMui] = useState(createThemeMUI(themeDefault));
  const [homeData, setHomeData] = useRecoilState(homeDataState);
  const [loading, setLoading] = useState(true);

  const getAvailableInFilter = () => {
    const isDeliveryOnly = window.location.hostname.includes("delivery.");
    const isLocalOnly = window.location.hostname.includes("cardapio.");

    if (isDeliveryOnly) return "DELIVERY";
    if (isLocalOnly) return "LOCAL";
    return "ALL";
  };

  useEffect(() => {
    if (slug) {
      const availableIn = getAvailableInFilter();
      api
        .get<HomeDataInterface>(`/${slug}?available_in=${availableIn}`)
        .then((resp) => {
          setHomeData(resp.data);
          Sentry.setUser({ id: slug });
        });
    }
  }, []);

  useEffect(() => {
    if (homeData?.company?.name) {
      setThemeMui(createThemeMUI(homeData.company.color));
      createThemeVariablesCSS(homeData.company.color);
      setLoading(false);
    }
  }, [homeData]);

  if (loading && slug)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        minHeight="90vh"
      >
        <CircularProgress color="inherit" />
      </Grid>
    );

  return (
    <ThemeProvider theme={themeMui}>
      <RouterProvider router={router} />
      <DialogProvider />
    </ThemeProvider>
  );
}
