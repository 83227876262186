import { useRecoilState, useRecoilValue } from "recoil";
import { cartDefaultState, cartState } from "../state/atoms/cart";
import { useState } from "react";
import { api } from "../services/api";
import { homeDataState } from "../state/atoms/homeData";
import {
  CartInterfaceAPI,
  CartInterface,
  CartProductInterface,
} from "../interfaces/cart";
import useDialog from "./useDialog";
import { OrderCreatedInterfaceAPI } from "../interfaces/order";
import { cartProductsQuantity } from "../state/selectors/cart";
import useAccount from "./useAccount";
import { deliveryWayDeliveryId } from "../services/static";

type noop = () => void;

const useCart = () => {
  const dialog = useDialog();
  const account = useAccount();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useRecoilState(cartState);
  const productsQuantity = useRecoilValue(cartProductsQuantity);
  const { company } = useRecoilValue(homeDataState);

  const handleSave = (
    type: "get" | "post" | "put" | "delete",
    url: string,
    payload?: any,
    onSuccess?: noop
  ) => {
    setLoading(true);
    api[type]<CartInterfaceAPI>(url, payload)
      .then((resp) => {
        setData(resp.data);
        setLoading(false);
        onSuccess?.();
      })
      .catch((err) => {
        setLoading(false);
        dialog("Houve um problema ⚠️", err.response?.data?.message);
      });
  };

  const createNewCart = () => {
    return api.post(`/carts/${company.id}`);
  };

  const addProduct = (item: CartProductInterface, onSuccess?: noop) => {
    if (data.id) {
      handleSave("post", `/carts/${data.id}/products`, item, onSuccess);
    } else {
      createNewCart().then((resp) => {
        handleSave("post", `/carts/${resp.data.id}/products`, item, onSuccess);
      });
    }
  };

  const removeProduct = (id: CartProductInterface["product_id"]) => {
    handleSave("delete", `/carts/${data.id}/products/${id}`);
  };

  const changeObservation = (
    observation: CartInterfaceAPI["observation"],
    onSucess?: noop
  ) => {
    handleSave(
      "put",
      `/carts/${data.id}/observation`,
      { observation },
      onSucess
    );
  };

  const changeScheduleAt = (
    scheduleAt: CartInterfaceAPI["scheduled_at"],
    onSucess?: noop
  ) => {
    handleSave(
      "post",
      `/carts/${data.id}/schedule`,
      { scheduled_at: scheduleAt },
      onSucess
    );
  };

  const changePaymentMethod = (
    id: CartInterfaceAPI["payment_method_id"],
    onSuccess?: noop
  ) => {
    handleSave(
      "put",
      `/carts/${data.id}/payment`,
      {
        payment_method_id: id,
      },
      onSuccess
    );
  };

  const changePaymentChange = (
    amount: CartInterfaceAPI["change"],
    onSuccess?: noop
  ) => {
    handleSave(
      "put",
      `/carts/${data.id}/payment`,
      {
        change: amount,
        payment_method_id: data.payment_method_id,
      },
      onSuccess
    );
  };

  const addDiscountCoupon = (
    couponCode: CartInterfaceAPI["coupon"],
    onSuccess?: noop
  ) => {
    handleSave(
      "post",
      `/carts/${data.id}/coupon/${couponCode}`,
      null,
      onSuccess
    );
  };

  const removeDiscountCoupon = (onSuccess?: noop) => {
    handleSave("delete", `/carts/${data.id}/coupon`, onSuccess);
  };

  const changeDeliveryWay = (
    deliveryWayId: CartInterfaceAPI["delivery_way"],
    onSuccess?: noop
  ) => {
    handleSave(
      "put",
      `/carts/${data.id}/delivery_way`,
      { delivery_way_id: deliveryWayId },
      onSuccess
    );
  };

  const changeDeliveryAddress = (
    address: CartInterfaceAPI["address"],
    onSuccess?: noop
  ) => {
    handleSave("put", `/carts/${data.id}/address`, { ...address }, onSuccess);
  };

  const changeCustomer = (
    customer: CartInterface["customer"],
    onSuccess?: noop
  ) => {
    handleSave("put", `/carts/${data.id}/customer`, customer, onSuccess);
  };

  const finishCart = () => {
    return api.post<OrderCreatedInterfaceAPI>(`/carts/${data.id}/finish`);
  };

  const deleteCart = async () => {
    setData(cartDefaultState);
  };

  const fillWithPreviousOrder = (onSuccess?: noop) => {
    const prevOrder = account.previousOrder;
    if (prevOrder) {
      if (prevOrder.delivery_way === deliveryWayDeliveryId) {
        changeDeliveryAddress(prevOrder.address, () => {
          changeDeliveryWay(prevOrder.delivery_way, () => {
            onSuccess?.();
            changePaymentMethod(prevOrder.payment_method_id, () => {
              changeCustomer(prevOrder.customer);
            });
          });
        });
      } else {
        changeDeliveryWay(prevOrder.delivery_way, () => {
          onSuccess?.();
          changePaymentMethod(prevOrder.payment_method_id, () => {
            changeCustomer(prevOrder.customer);
          });
        });
      }
    }
  };

  return {
    data,
    productsQuantity,
    loading,
    setLoading,
    fillWithPreviousOrder,
    addProduct,
    addDiscountCoupon,
    removeProduct,
    removeDiscountCoupon,
    changeObservation,
    changePaymentMethod,
    changePaymentChange,
    changeDeliveryWay,
    changeDeliveryAddress,
    changeScheduleAt,
    changeCustomer,
    finishCart,
    deleteCart,
  };
};

export default useCart;
