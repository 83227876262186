import { atom } from "recoil";
import { CartInterfaceAPI } from "../../interfaces/cart";

export const cartDefaultState: CartInterfaceAPI = {
  id: "",
  delivery_tax: 0,
  change: 0,
  payment_method_id: "",
  order_status_id: "",
  delivery_way: "",
  created_at: "",
  observation: null,
  scheduled_at: null,
  payment_status: "PENDING",
  payment_transaction_id: null,
  payment_pix_code: null,
  payment_pix_expiry_date: null,
  coupon: "",
  name: "",
  discount: 0,
  increase: 0,
  increase_tax_method: 0,
  subtotal: 0,
  total: 0,
  address: {
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    zipcode: "",
  },
  customer: {
    id: null,
    name: null,
    phone: null,
    document: null,
  },
  products: [],
};

export const cartState = atom<CartInterfaceAPI>({
  key: "cart",
  default: cartDefaultState,
});
