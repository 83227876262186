import { theme } from "../styles/theme";

export const orderStatusCanceled = {
  title: "Cancelado",
  subtitle: "Infelizmente seu pedido foi cancelado.",
  id: "137a40e6-3b0a-47ea-a21f-6c7c980ad8c5",
  color: theme.error,
};

export const orderStatusPending = {
  title: "Aguardando confirmação",
  subtitle: "Aguarde o local aprovar seu pedido.",
  id: "9f7fdfdb-f8d7-4ae6-8511-bfd65862037a",
  color: theme.warning,
};

export const orderStatusProgress = {
  title: "Preparando pedido",
  subtitle: "O pedido está sendo preparado.",
  id: "1f5ad6e5-452f-422f-9dd2-aed89737ab77",
  color: theme.primary,
};

export const orderStatusPickup = {
  title: "Aguardando retirada",
  subtitle: "Seu pedido está disponível.",
  id: "c4ac5420-eef5-472b-ae15-075ef5a434c8",
  color: theme.secondary,
};

export const orderStatusDelivery = {
  title: "Entrega em andamento",
  subtitle: "Seu pedido chegará em breve.",
  id: "4017b022-14c1-4851-a470-68cfcf36eff1",
  color: theme.secondary,
};

export const orderStatusDone = {
  title: "Concluído",
  subtitle: "Seu pedido foi entregue.",
  id: "49c4d3a1-04c2-4943-9b9b-2af24fc3a010",
  color: theme.tertiary,
};

export const orderStatusPickupMap = [
  orderStatusPending,
  orderStatusProgress,
  orderStatusPickup,
  orderStatusDone,
];

export const orderStatusDeliveryMap = [
  orderStatusPending,
  orderStatusProgress,
  orderStatusDelivery,
  orderStatusDone,
];

export const orderStatusAllMap = [
  orderStatusCanceled,
  orderStatusPending,
  orderStatusProgress,
  orderStatusPickup,
  orderStatusDelivery,
  orderStatusDone,
];

// API {{baseUrl}}/filter/delivery-way
export const deliveryWayMap = [
  {
    id: "b780c6c4-b428-4918-a5da-faed21964dc3",
    label: "Retirada no local",
  },
  {
    id: "eaca0ce7-c64e-4eab-b8af-4c5a13896bc6",
    label: "Entrega no endereço",
  },
];

export const deliveryWayPickupId = deliveryWayMap[0].id;
export const deliveryWayDeliveryId = deliveryWayMap[1].id;

export const PIX_METHOD_CODE = "b14a0ffc-f1de-45c6-abf6-438d8fb74f80";
