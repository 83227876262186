import { AccountCircleRounded, EastRounded } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "./MyOrdersResumeStyles";
import useAccount from "../../hooks/useAccount";

function MyOrdersResume() {
  const navigate = useNavigate();
  const account = useAccount();
  const customerName = account.previousOrder?.customer.name;

  const handleOpenDetails = () => {
    navigate(`./meus-pedidos`);
  };

  return (
    <Wrapper onClick={handleOpenDetails} show={account.isAuthenticated}>
      <Container>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            flex={0}
            paddingRight="15px"
            display="flex"
            alignItems="center"
          >
            <AccountCircleRounded fontSize="large" />
          </Grid>
          <Grid
            item
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
            flex={1}
          >
            <b>{customerName || "Meus pedidos"}</b>
            <span>{!!customerName ? "Meus pedidos" : ""}</span>
          </Grid>
          <Grid item flex={0}>
            <EastRounded fontSize="medium" />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default MyOrdersResume;
