import { Environment, ShopContext } from "../interfaces/utils";

export const getEnvironment = (): Environment => {
  const { hostname } = window.location;

  if (hostname.includes("localhost")) {
    return "development";
  }

  if (hostname.includes("homolog")) {
    return "staging";
  }

  return "production";
};

export const getShopContext = (): ShopContext => {
  const { hostname } = window.location;

  if (hostname.includes("delivery")) {
    return "delivery";
  }

  if (hostname.includes("cardapio")) {
    return "cardapio";
  }

  return "root";
};
