import { Grid } from "@mui/material";
import { SectionHeaderProps } from "./SectionHeaderTypes";
import { Description, GridStyled, Title } from "./SectionHeaderStyles";

function SectionHeader({
  title,
  description,
  actions,
  variant,
  bottomDivider,
}: SectionHeaderProps) {
  return (
    <GridStyled
      container
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      bottomDivider={bottomDivider}
      flexDirection={["column", "row"]}
    >
      <Grid item xs={12} sm={actions ? 6 : 12}>
        <Title variant={variant}>{title}</Title>
        <Description>{description}</Description>
      </Grid>
      {actions && (
        <Grid
          item
          xs={12}
          sm={6}
          display="flex"
          justifyContent={["flex-start", "flex-end"]}
        >
          {actions}
        </Grid>
      )}
    </GridStyled>
  );
}
export default SectionHeader;
