import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./app";
import "./styles/general.css";
import { RecoilRoot } from "recoil";
import { getEnvironment } from "./utils/setup";
import Hotjar from "@hotjar/browser";

const envinroment = getEnvironment();

if (envinroment === "production") {
  // Sentry inicialization
  Sentry.init({
    dsn: "https://07123cc6340b4612a49485faff6539b7@o4505217781923840.ingest.sentry.io/4505217783431168",
    environment: envinroment,
  });

  // Hotjar inicialization
  const siteId = 3904549;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
} else {
  window.slug = window.location.pathname.split("/")[1];
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);
